<template>

    <div class="row">
        <!-- Filters -->
        <div class="col-lg-5 mb-2">
            <table width="100%" border="0">
                <tr>
                    <td>
                        <VueDatePicker v-model="filterDateFr" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                    </td>
                    <td class="text-primary fw-bold px-2" > - </td>
                    <td>
                        <VueDatePicker v-model="filterDateTo" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                    </td>
                </tr>
            </table>
        </div>

        <div class="col-lg-4 mb-2">
            <div class="input-group">
                <span class="input-group-text">Invoice ID</span>
                <input type="text" v-model="filterId" class="form-control" placeholder="Invoice ID" @dblclick="filterId = ''">
                <span v-if="filterId && filterId.length > 0" class="input-group-text text-secondary bg-light isLink" @click="filterId = ''" style="cursor: pointer">
                    <i class="fa-solid fa-xmark"></i>
                </span>
            </div>
        </div>

        <div class="col-md-6 col-lg-3 mb-1">
            <div class="d-flex flex-wrap justify-content-between">
                <span class="filter-pill-border-grey">
                    <span class="filter-item-pill-grey" :class="filterStatus === '' ? 'active' : ''" @click="filterStatus = ''">ALL</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill-grey" :class="filterStatus === 'PENDING' ? 'active' : ''" @click="filterStatus = 'PENDING'">PENDING</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill-grey" :class="filterStatus === 'PAID' ? 'active' : ''" @click="filterStatus = 'PAID'">PAID</span>
                </span>
            </div>
        </div>

        <div class="col-lg-3 mb-2">
            <div class="input-group">
                <span class="input-group-text">Currency</span>
                <select class="form-select" v-model="filterCur">
                    <option value="">ALL</option>
                    <option value="MYR">MYR</option>
                    <option value="USD">USD</option>
                </select>
            </div>
        </div>
        <div class="col-lg-4 mb-2">
            <div class="input-group">
                <span class="input-group-text">Amount</span>
                <input type="text" v-model="filterAmtFr" class="form-control" placeholder="Amount" @dblclick="filterAmtFr = 0">
                <span class="input-group-text">-</span>
                <input type="text" v-model="filterAmtTo" class="form-control" placeholder="Amount" @dblclick="filterAmtTo = 22">
            </div>
        </div>

        <!-- Pagination Top -->
        <div class="col-12"><br /></div>
        <div class="col-lg-6 align-middle">
            <table>
                <tr>
                    <td>Page {{ pgPage }} / {{pgLastPage}}</td>
                    <td class="px-2"><i class="fa-solid fa-circle-small" style="font-size: 4px; padding-bottom: 5px; color: #dadcde"></i></td>
                    <td>{{pgRcdFr}} - {{pgRcdTo}} of {{total}} items</td>
                </tr>
            </table>
        </div>

        <div class="col-lg-6">
            <table class="text-center" align="right">
                <tr>
                    <td v-if="total > 0 && !pgArrPage.includes(1)" width="30px">
                        <a class="isLink" href="#" @click="first"><i class="fa-solid fa-angles-left"></i></a>
                    </td>
                    <td v-if="pgArrPage[0] > 1" width="35px">
                        <a class="isLink" href="#" @click="previous"><i class="fa-solid fa-angle-left"></i></a>
                    </td>
                    <td width="30px" v-for="p in pgArrPage" :key="p">
                        <a @click="pgPage = p" class="isLink" :class="pgPage === p ? 'text-primary' : ''" href="javascript:void(0);">{{p}}</a>
                    </td>
                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="30px">
                        <a class="isLink" href="#" @click="next"><i class="fa-solid fa-angle-right"></i></a>
                    </td>
                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="35px">
                        <a class="isLink" href="#" @click="last"><i class="fa-solid fa-angles-right"></i></a>
                    </td>
                    <td class="align-middle"><div class="vr ms-1 mt-1"></div></td>
                    <td>
                        <a class="nav-link dropdown-toggle" href="#" id="sortLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i v-if="sortField === 'dateCreate' && sortOrder === 'asc'" class="fa-solid fa-arrow-down-short-wide text-primary"></i>
                            <i v-if="sortField === 'dateCreate' && sortOrder === 'desc'" class="fa-solid fa-arrow-up-short-wide text-primary"></i>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="sortLink">
                            <li>
                                <a class="dropdown-item" :class="sortField === 'dateCreate' && sortOrder === 'asc' ? 'active' : ''" href="#" @click="sortField = 'dateCreate'; sortOrder = 'asc'">
                                    <i class="fa-solid fa-arrow-down-short-wide me-1"></i>Date ASC
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" :class="sortField === 'dateCreate' && sortOrder === 'desc' ? 'active' : ''" href="#" @click="sortField = 'dateCreate'; sortOrder = 'desc'">
                                    <i class="fa-solid fa-arrow-up-short-wide me-1"></i>
                                    Date DESC
                                </a>
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
        </div>

        <!-- Datagrid -->
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Date Create</th>
                            <th>Invoice ID</th>
                            <th>Subscription</th>
                            <th>Billing Period</th>
                            <th class="text-end">#License</th>
                            <th class="text-end">Amount</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="i in arrDg" :key="i" >
                            <td>{{ func.convDateTimeFormat(i.dateCreate, 'datetime') }}</td>
                            <td class="fw-bold isLink" @click="getInvoice(i)" data-bs-toggle="modal" data-bs-target="#mdlInvoiceDet">{{ i.invoiceId }}</td>
                            <td>{{ i.invoicePlan }} {{ i.subscription === 'ANNUM' ? '(1 Year)' : '' }}</td>
                            <td>{{ i.dateBillFrom }} to {{ i.dateBillTo }}</td>
                            <td class="text-end">{{ i.totalLicense }}</td>
                            <td class="text-end">{{ i.currency }} {{ i.amount }}</td>
                            <td>{{ i.paymentStatus }}</td>
                            <td class="text-end" @click.prevent="downloadInvoice(i.invoiceId)">
                                <Popper class="popperDark" arrow hover content="Download invoice">
                                    <i class="fa-solid fa-download fa-lg isLink"></i>
                                </Popper>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Pagination Bottom -->
        <div class="col-lg-6 align-middle">
            <table>
                <tr>
                    <td>Page {{ pgPage }} / {{pgLastPage}}</td>
                    <td class="px-2"><i class="fa-solid fa-circle-small" style="font-size: 4px; padding-bottom: 5px; color: #dadcde"></i></td>
                    <td>{{pgRcdFr}} - {{pgRcdTo}} of {{total}} items</td>
                </tr>
            </table>
        </div>

        <div class="col-lg-6">
            <table class="text-center" align="right">
                <tr>
                    <td v-if="total > 0 && !pgArrPage.includes(1)" width="30px">
                        <a class="isLink" href="#" @click="first"><i class="fa-solid fa-angles-left"></i></a>
                    </td>
                    <td v-if="pgArrPage[0] > 1" width="35px">
                        <a class="isLink" href="#" @click="previous"><i class="fa-solid fa-angle-left"></i></a>
                    </td>
                    <td width="30px" v-for="p in pgArrPage" :key="p">
                        <a @click="pgPage = p" class="isLink" :class="pgPage === p ? 'text-primary' : ''" href="javascript:void(0);">{{p}}</a>
                    </td>
                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="30px">
                        <a class="isLink" href="#" @click="next"><i class="fa-solid fa-angle-right"></i></a>
                    </td>
                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="35px">
                        <a class="isLink" href="#" @click="last"><i class="fa-solid fa-angles-right"></i></a>
                    </td>
                    <td class="align-middle"><div class="vr ms-1 mt-1"></div></td>
                    <td>
                        <a class="nav-link dropdown-toggle" href="#" id="sortLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i v-if="sortField === 'dateCreate' && sortOrder === 'asc'" class="fa-solid fa-arrow-down-short-wide text-primary"></i>
                            <i v-if="sortField === 'dateCreate' && sortOrder === 'desc'" class="fa-solid fa-arrow-up-short-wide text-primary"></i>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="sortLink">
                            <li>
                                <a class="dropdown-item" :class="sortField === 'dateCreate' && sortOrder === 'asc' ? 'active' : ''" href="#" @click="sortField = 'dateCreate'; sortOrder = 'asc'">
                                    <i class="fa-solid fa-arrow-down-short-wide me-1"></i>Date ASC
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" :class="sortField === 'dateCreate' && sortOrder === 'desc' ? 'active' : ''" href="#" @click="sortField = 'dateCreate'; sortOrder = 'desc'">
                                    <i class="fa-solid fa-arrow-up-short-wide me-1"></i>
                                    Date DESC
                                </a>
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
        </div>

    </div>

    <div
        class="modal fade"
        id="mdlInvoiceDet"
        tabindex="-1"
        aria-labelledby="invoiceDet"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Invoice Detail</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <table v-if="invoice" class="table table-borderless">
                        <tbody>
                            <tr>
                                <td class="fw-bold">Date Create</td>
                                <td>{{ func.convDateTimeFormat(invoice.dateCreate, 'datetime') }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Invoice ID</td>
                                <td>{{ invoice.invoiceId }}</td>
                            </tr>
                            <tr v-if="invoice.paymentId !== ''">
                                <td class="fw-bold">Ref Payment ID</td>
                                <td>{{ invoice.paymentId }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Plan/Package</td>
                                <td>{{ invoice.invoicePlan }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Commitment</td>
                                <td>{{ invoice.subscription === 'ANNUM' ? '1 Year' : '-' }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Billing Period</td>
                                <td>{{invoice.dateBillFrom}} to {{ invoice.dateBillTo }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Total License</td>
                                <td>{{invoice.totalLicense}}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Amount</td>
                                <td>{{invoice.currency}} {{ invoice.amount }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Payment Status</td>
                                <td>
                                    <span v-if="invoice.paymentStatus === 'PAID'" class="fw-bold text-success">{{ invoice.paymentStatus }}</span>
                                    <span v-else class="fw-bold text-danger">{{ invoice.paymentStatus }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Payee</td>
                                <td v-if="invoice.jUser">
                                    <div>
                                        <table width="100%" border="0">
                                            <tr>
                                                <td class="float-start pt-1" width="40px">
                                                    <ImgAvatar isUser="true" :id="invoice.jUser.jAvatar.userId" displayName="" :isVerified="invoice.jUser.isVerified" :isActive="invoice.jUser.status" privilege="" :key="invoice.jUser.userId" align="center" width="35" height="35" />
                                                </td>
                                                <td class="float-start ps-2">
                                                    <div>
                                                        <span v-if="invoice.jUser.fullName !== '' && invoice.jUser.fullName !== undefined && invoice.jUser.displayName !== '' && invoice.jUser.displayName !== undefined">
                                                            {{ invoice.jUser.fullName }} <span class="text-grey"><i class="fa-regular fa-at mx-1"></i>{{invoice.jUser.displayName}}</span>
                                                        </span>
                                                        <span v-else-if="invoice.jUser.fullName !== '' && invoice.jUser.fullName !== undefined">
                                                            {{ invoice.jUser.fullName }}
                                                        </span>
                                                        <span v-else-if="invoice.jUser.displayName !== '' && invoice.jUser.displayName !== undefined">
                                                            {{invoice.jUser.displayName}}
                                                        </span>
                                                    </div>
                                                    <div class="small text-grey">{{ invoice.jUser.userId }}</div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer" style="justify-content: space-between;">
                    <button type="button" class="btn btn-secondary" @click="downloadInvoice(invoice.invoiceId)" data-bs-dismiss="modal">
                        <i class="fa-solid fa-download me-2"></i>Download
                    </button>
                    <button type="button" class="btn btn-outline-secondary me-2" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import funcs from '@/functions/function'
import Popper from 'vue3-popper'
import ImgAvatar from '@/components/ImgAvatar.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'Invoice',
    props: [],
    emits: [],
    components: { Popper, VueDatePicker, ImgAvatar },
    setup (props, { emit }) {

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const func = funcs

        const filterDateFr  = ref('')
        const filterDateTo  = ref('')
        const filterId      = ref('')
        const filterStatus  = ref('')
        const filterCur     = ref('')
        const filterAmtFr   = ref(0)
        const filterAmtTo   = ref(22)

        const arrDg = ref([])
        const total = ref(0)
        const rcdFr = ref(0) // record index from 
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const invoice = ref(null)   // selected invoice

        const pgRcdFr   = ref(1)    // Pagination rcd fr
        const pgRcdTo   = ref(1)    // Pagination rcd to
        const pgPage    = ref(1)    // Pagination page no selected
        const pgLastPage= ref(1)    // Pagination last page
        const pgNoPageList= ref(3)  // Pagination list n page number between previous and next
        const pgArrPage = ref([])   // Pagination pages listed

        const sortField = ref('dateCreate')
        const sortOrder = ref('desc')

        watch([filterDateFr, filterDateTo, filterId, filterStatus, filterCur, filterAmtFr, filterAmtTo, sortField, sortOrder], () => {
            filterAmtFr.value = filterAmtFr.value * 1
            filterAmtTo.value = filterAmtTo.value * 1

            getDgList(true)
        })
       
        watch([pgPage], () => {
            // console.info('- - WATCH: pgPage', pgPage.value)
            if (pgPage.value === 1) {
                rcdFr.value = 0
            } else {
                rcdFr.value = (pgPage.value - 1) * rcdDisplay.value
            }

            pgRcdFr.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : (pgPage.value -1 ) * rcdDisplay.value + 1
            pgRcdTo.value = pgRcdFr.value + rcdDisplay.value - 1 >= total.value ? total.value : pgRcdFr.value + rcdDisplay.value - 1
            getDgList(false)
        })

        const getInvoice = (data) => {
            invoice.value = data
        }

        const downloadInvoice = async (invoiceId) => {
            const url = axios.defaults.baseURL + 'invoice/download/' + invoiceId
            console.info('invoice url', url)
            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = 'invoice_'+invoiceId

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
        }

        const first = () => {
            let listBegin = 1
            let listLast = pgLastPage.value >= pgNoPageList.value ? pgNoPageList.value : pgLastPage.value

            pgArrPage.value = []
            for (var i = listBegin; i <= listLast; i++) {
                pgArrPage.value.push(i)
            } 

            if (total.value === 0) pgRcdFr.value = 0
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const previous = () => {
            let listBegin = pgArrPage.value[0] - pgNoPageList.value <= 1 ? 1 : pgArrPage.value[0] - pgNoPageList.value
            let listLast = listBegin + (pgNoPageList.value - 1) >= pgLastPage.value ? pgLastPage.value : listBegin + (pgNoPageList.value - 1)

            pgArrPage.value = []
            for (var i = listBegin; i <= listLast; i++) {
                pgArrPage.value.push(i)
            } 
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const next = () => {
            let listBegin = pgArrPage.value[pgArrPage.value.length-1] + 1
            let listLast = listBegin + (pgNoPageList.value - 1) >= pgLastPage.value ? pgLastPage.value : listBegin + (pgNoPageList.value - 1)

            pgArrPage.value = []
            for (var i = listBegin; i <= listLast; i++) {
                pgArrPage.value.push(i)
            } 
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const last = () => {
            let listBegin = Math.ceil(pgLastPage.value / pgNoPageList.value) - 1 
            listBegin = listBegin * pgNoPageList.value + 1

            pgArrPage.value = []
            for (var i = listBegin; i <= pgLastPage.value; i++) {
                pgArrPage.value.push(i)
            } 
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const getDgList = (setToFirst) => {
            let p = { 
                limit: rcdFr.value + ',' + rcdDisplay.value,
                orderBy: [
                    {
                        field: sortField.value,
                        order: sortOrder.value
                    }
                ] 
            }

            if (filterDateFr.value === null || filterDateTo.value === null) {
                // either one date empty, skip filter by date
            } else {
                p.dateCreateFrom = filterDateFr.value + ' 00:00:00'
                p.dateCreateTo = filterDateTo.value + ' 23:59:59'
            }

            if (filterId.value !== '' && filterId.value.length >= 3) {
                p.invoiceId = filterId.value
            }

            if (filterStatus.value === 'PENDING' || filterStatus.value === 'PAID') {
                p.paymentStatus = filterStatus.value
            }

            if (filterAmtFr.value >= 0 && filterAmtTo.value >= filterAmtFr.value ) {
                p.amountFrom = filterAmtFr.value
                p.amountTo = filterAmtTo.value
            }

            if (filterCur.value === 'MYR' || filterCur.value === 'USD') {
                p.currency = filterCur.value
            }

            console.info('getDgList REQ',JSON.stringify(p))

            axios.post('/invoice/list', p)
            .then((res) => {
                if (res.data.status === 1) {
                    // console.info('- - success',  JSON.stringify(res.data.data))
                    
                    total.value = res.data.description.rowCount
                    arrDg.value = res.data.data
                    // console.info('arrDg RES', JSON.stringify(res.data))
                    
                    // show pagination
                    pgLastPage.value = Math.ceil(total.value / rcdDisplay.value)
                    if (setToFirst === true) {
                        pgPage.value = 1
                        first()
                    }
                } else {
                    func.addLog('comp: invoice', 'getDgList', res)

                    if (res && res.data !== null && res.data !== undefined) {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                            
                    }

                }
            })
            .catch((error) => {
                func.addLog('comp: invoice', 'getDgList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
            
            })
        }

        onMounted(async() => {
            filterDateFr.value = func.convDateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 8), 'date')
            filterDateTo.value = func.convDateTimeFormat(new Date(), 'date')

            getDgList()
        })

        return {
            store, route, router, func, first, last, previous, next, 
            filterDateFr, filterDateTo, filterId, filterStatus, filterCur, filterAmtFr, filterAmtTo, 
            arrDg, total, rcdFr, rcdDisplay, pgRcdFr, pgRcdTo, pgPage, pgLastPage, pgNoPageList, pgArrPage, sortField, sortOrder,
            downloadInvoice, getInvoice, invoice
        }
    }

}
</script>

<style>
    
</style>